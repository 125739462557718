
@import "./assets/variables.scss";

body {
  background-color: $background;
  font-family: Arial, Helvetica, sans-serif;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

